import { Component, OnInit } from "@angular/core";

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Overview",
  
    icon: "icon-chart-pie-36",
    class: ""
  },
  {
    path: "/tables",
    title: "Purchases",
    icon: "icon-money-coins",
    class: ""
  },
  {
    path: "/notifications",
    title: "Claims",
    icon: "icon-book-bookmark",
    class: ""
  },
  {
    path: "/policy",
    title: "Policy",
    icon: "icon-simple-add",
    class: ""
  },

  // {
  //   path: "/user",
  //   title: "User Profile",
  //   icon: "icon-single-02",
  //   class: ""
  // },
  // {
  //   path: "/typography",
  //   title: "User Information",
  //   icon: "icon-single-02",
  //   class: ""
  // },
  {
    path:"/signout",
    title:"Sign Out",
    icon:"icon-button-power",
    class:""
  }

];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() {}

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }
}
